/* GENERATED FILE */
import React, { forwardRef } from "react";

import {
  IconWeight,
  IconProps,
  PaintFunction,
  renderPathForWeight,
} from "../lib";
import IconBase, { RenderFunction } from "../lib/IconBase";

const pathsByWeight = new Map<IconWeight, PaintFunction>();

pathsByWeight.set("bold", (color: string) => (
  <>
    <circle
      cx="128.00098"
      cy="128.00195"
      r="44"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    />
    <path
      d="M197.40919,80.65057a83.91174,83.91174,0,0,1,6.28744,10.91L229.6429,105.964a103.88962,103.88962,0,0,1,.03183,44.05764L203.714,164.433a83.912,83.912,0,0,1-6.30466,10.90011l.49943,29.67182a103.88959,103.88959,0,0,1-38.13912,22.05638l-25.46093-15.27692a83.91075,83.91075,0,0,1-12.5921-.01L96.26983,227.04288a103.88979,103.88979,0,0,1-38.17095-22.00126l.49974-29.68828a83.91174,83.91174,0,0,1-6.28744-10.91005L26.36491,150.0399a103.88962,103.88962,0,0,1-.03182-44.05764L52.29376,91.5709a83.9124,83.9124,0,0,1,6.30466-10.9001L58.099,50.999A103.88973,103.88973,0,0,1,96.23811,28.94259l25.46094,15.27693a83.91061,83.91061,0,0,1,12.59209.00995L159.738,28.961a103.88951,103.88951,0,0,1,38.171,22.00126Z"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="24"
    />
  </>
));

pathsByWeight.set("duotone", (color: string) => (
  <>
    <path
      d="M229.645,105.96387,203.69873,91.56055a83.92517,83.92517,0,0,0-6.2876-10.90992l.5-29.68823a103.88966,103.88966,0,0,0-38.17138-22.00146L134.293,44.22949a83.89083,83.89083,0,0,0-12.5918-.01L96.24023,28.94263A103.8856,103.8856,0,0,0,58.10107,50.999l.49952,29.67188A83.85065,83.85065,0,0,0,52.2959,91.5708L26.335,105.98218A103.8963,103.8963,0,0,0,26.3667,150.04L52.313,164.44336a83.92506,83.92506,0,0,0,6.2876,10.90991l-.5,29.68823A103.88954,103.88954,0,0,0,96.272,227.043l25.44678-15.26856a83.89083,83.89083,0,0,0,12.5918.01l25.46093,15.27686a103.88532,103.88532,0,0,0,38.13916-22.0564l-.49951-29.67187a83.79517,83.79517,0,0,0,6.30469-10.8999l25.96094-14.41138A103.8963,103.8963,0,0,0,229.645,105.96387ZM128.00586,176.002a48,48,0,1,1,48-48A47.99987,47.99987,0,0,1,128.00586,176.002Z"
      opacity="0.2"
    />
    <circle
      cx="128.00586"
      cy="128.00195"
      r="48"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <path
      d="M197.41115,80.65057a83.91167,83.91167,0,0,1,6.28743,10.91L229.64485,105.964a103.88962,103.88962,0,0,1,.03183,44.05764L203.716,164.433a83.91089,83.91089,0,0,1-6.30466,10.90011l.49944,29.67182a103.88963,103.88963,0,0,1-38.13913,22.05638l-25.46093-15.27692a83.91075,83.91075,0,0,1-12.5921-.01L96.27178,227.04288a103.88987,103.88987,0,0,1-38.171-22.00126l.49974-29.68828a83.91167,83.91167,0,0,1-6.28743-10.91005L26.36687,150.0399a103.88946,103.88946,0,0,1-.03183-44.05764L52.29571,91.5709a83.91132,83.91132,0,0,1,6.30467-10.9001L58.10094,50.999A103.88969,103.88969,0,0,1,96.24007,28.94259L121.701,44.21952a83.91075,83.91075,0,0,1,12.5921.00995L159.73994,28.961a103.88959,103.88959,0,0,1,38.171,22.00126Z"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
  </>
));

pathsByWeight.set("fill", () => (
  <>
    <path d="M237.46191,104.26611a8.00135,8.00135,0,0,0-3.93554-5.29687L209.75049,85.77051c-1.31592-2.55078-2.75391-5.04639-4.29981-7.46094l.458-27.2124a8.00049,8.00049,0,0,0-2.62158-6.05811A111.13811,111.13811,0,0,0,162.17773,21.3418a8.00131,8.00131,0,0,0-6.55468.75927l-23.31836,13.9917c-2.86865-.13672-5.75-.13916-8.61133-.00683L100.35547,22.08252a8.003,8.003,0,0,0-6.55762-.75879A111.15557,111.15557,0,0,0,52.7207,45.07715a8.00055,8.00055,0,0,0-2.61963,6.05664l.45752,27.18994c-1.55078,2.41455-2.99365,4.90869-4.31152,7.4541l-23.7959,13.21a8.0003,8.0003,0,0,0-3.93555,5.29932,111.13522,111.13522,0,0,0,.03223,47.45068,8.00135,8.00135,0,0,0,3.93555,5.29688L46.25928,170.2334c1.31592,2.55078,2.7539,5.04639,4.2998,7.46094l-.458,27.2124a8.00053,8.00053,0,0,0,2.62159,6.0581A111.13809,111.13809,0,0,0,93.832,234.66211a8.00177,8.00177,0,0,0,6.55469-.75928l23.31836-13.9917c2.86816.13623,5.749.13867,8.61133.00684l23.33789,14.00342a8.00237,8.00237,0,0,0,6.55761.75879,111.15549,111.15549,0,0,0,41.07715-23.75342,8.00052,8.00052,0,0,0,2.61963-6.05664l-.45752-27.18994c1.55078-2.41455,2.99365-4.9087,4.31153-7.45411l23.79589-13.21a8.0002,8.0002,0,0,0,3.93555-5.29931A111.13527,111.13527,0,0,0,237.46191,104.26611ZM172.0049,128.002a44,44,0,1,1-44-44A44.04972,44.04972,0,0,1,172.0049,128.002Z" />
  </>
));

pathsByWeight.set("light", (color: string) => (
  <>
    <circle
      cx="128.00293"
      cy="128.00195"
      r="48"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
    />
    <path
      d="M197.40822,80.65057a83.91167,83.91167,0,0,1,6.28743,10.91L229.64192,105.964a103.88962,103.88962,0,0,1,.03183,44.05764L203.71308,164.433a83.9121,83.9121,0,0,1-6.30467,10.90011l.49944,29.67182a103.88955,103.88955,0,0,1-38.13913,22.05638l-25.46093-15.27692a83.91075,83.91075,0,0,1-12.5921-.01L96.26885,227.04288A103.88987,103.88987,0,0,1,58.0979,205.04162l.49974-29.68828a83.91167,83.91167,0,0,1-6.28743-10.91005L26.36394,150.0399a103.88946,103.88946,0,0,1-.03183-44.05764L52.29278,91.5709a83.91132,83.91132,0,0,1,6.30467-10.9001L58.098,50.999A103.88969,103.88969,0,0,1,96.23714,28.94259l25.46093,15.27693a83.91075,83.91075,0,0,1,12.5921.00995L159.737,28.961a103.88959,103.88959,0,0,1,38.171,22.00126Z"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
    />
  </>
));

pathsByWeight.set("thin", (color: string) => (
  <>
    <circle
      cx="128.00195"
      cy="128.00195"
      r="48"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="8"
    />
    <path
      d="M197.40724,80.65057a83.91052,83.91052,0,0,1,6.28743,10.91L229.641,105.964a103.88962,103.88962,0,0,1,.03182,44.05764L203.7121,164.433a83.91318,83.91318,0,0,1-6.30466,10.90011l.49943,29.67182a103.88959,103.88959,0,0,1-38.13912,22.05638l-25.46094-15.27692a83.91075,83.91075,0,0,1-12.5921-.01L96.26788,227.04288a103.88971,103.88971,0,0,1-38.171-22.00126l.49974-29.68828a83.91174,83.91174,0,0,1-6.28744-10.91005L26.363,150.0399a103.88962,103.88962,0,0,1-.03183-44.05764L52.29181,91.5709a83.91125,83.91125,0,0,1,6.30466-10.9001L58.097,50.999A103.88973,103.88973,0,0,1,96.23616,28.94259l25.46093,15.27693a83.91075,83.91075,0,0,1,12.5921.00995L159.736,28.961a103.88951,103.88951,0,0,1,38.171,22.00126Z"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="8"
    />
  </>
));

pathsByWeight.set("regular", (color: string) => (
  <>
    <circle
      cx="128.00098"
      cy="128.00195"
      r="48"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <path
      d="M197.40626,80.65057a83.91174,83.91174,0,0,1,6.28744,10.91L229.64,105.964a103.88962,103.88962,0,0,1,.03183,44.05764L203.71112,164.433a83.912,83.912,0,0,1-6.30466,10.90011l.49943,29.67182a103.88959,103.88959,0,0,1-38.13912,22.05638l-25.46093-15.27692a83.91075,83.91075,0,0,1-12.5921-.01L96.2669,227.04288a103.88979,103.88979,0,0,1-38.171-22.00126l.49974-29.68828a83.91052,83.91052,0,0,1-6.28743-10.91005L26.362,150.0399a103.88962,103.88962,0,0,1-.03182-44.05764L52.29083,91.5709a83.9124,83.9124,0,0,1,6.30466-10.9001L58.09606,50.999A103.88973,103.88973,0,0,1,96.23518,28.94259l25.46094,15.27693a83.91075,83.91075,0,0,1,12.5921.00995L159.73505,28.961a103.88943,103.88943,0,0,1,38.171,22.00126Z"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
  </>
));

const renderPath: RenderFunction = (weight: IconWeight, color: string) =>
  renderPathForWeight(weight, color, pathsByWeight);

const GearSix = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <IconBase ref={ref} {...props} renderPath={renderPath} />
));

GearSix.displayName = "GearSix";

export default GearSix;
